import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import iView from "iview";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "iview/dist/styles/iview.css"; // 使用 CSS
import store from "./vuex/store";
import Vuex from "vuex";
// import "./assets/css/screen.less"
import "./assets/css/common.less"
Vue.use(Vuex);

Vue.config.productionTip = false;
Vue.use(iView);
Vue.use(ElementUI);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
