import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./Home/index";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/layout/index.vue"),
    children: Home
  },
  {
    path: "/home",
    component: () => import("@/views/home/index.vue")
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.afterEach(() => {
  window, scrollTo(0, 0);
});

export default router;
