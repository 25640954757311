import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

let store = new Vuex.Store({
  state: {
    recordCars: [],
    count: ["50"]
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,

      reducer(val) {
        return {
          // recordCars
          recordCars: val.recordCars
        };
      }
    })
  ],
  getters: {},
  mutations: {
    getRecordCars(state, n) {
      state.recordCars.push(n)
      let obj = {};
      state.recordCars = state.recordCars.reduce(function (item, next) {
        obj[next.id] ? '' : obj[next.id] = true && item.push(next);
        return item;
      }, []);
    },
    addcount(state, n) {
      state.count.push(n);
    }
  },
  actions: {}
});

export default store;