<template>
  <div id="app">
    <router-view></router-view>
    <div class="wechat-loading" vertical id="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    if (this._isMobile()) {
      // window.location.href='www.baidu.com'
      // location.replace('www.baidu.com')
      return
    } else {
      return
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="less">
#app {
  font-size: 16px;
}
</style>
