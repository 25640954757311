const router = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home/index")
  },
  {
    path: "/testdrive",
    name: "Testdrive",
    component: () => import("@/views/home/testdrive/index")
  },
  {
    path: "/detail",
    name: "Detail",
    redirect: "/detail/defaultview",
    component: () => import("@/views/detail/index"),
    children: [
      {
        path: "/detail/defaultview",
        name: "Defaultview",
        component: () => import("@/views/detail/defaultview/index")
      },
      {
        path: "/detail/configure",
        name: "Configure",
        component: () => import("@/views/detail/configure/index")
      },
      {
        path: "/detail/360check",
        name: "Check",
        component: () => import("@/views/detail/360check/index")
      },
      {
        path: "/detail/ele-360check",
        name: "Check",
        component: () => import("@/views/detail/ele-360check/index")
      },
      {
        path: "/detail/maintaindetail",
        name: "Maintaindetail",
        component: () => import("@/views/detail/maintaindetail/index")
      }
    ]
  },
  {
    path: "/finance",
    name: "Finance",
    component: () => import("@/views/finance/index")
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/about/index")
  },
  {
    path: "/concatus",
    name: "Concatus",
    component: () => import("@/views/concatus/index")
  },
  {
    path: "/guarantee",
    name: "Guarantee",
    component: () => import("@/views/guarantee/index")
  },
  {
    path: "/suggestion",
    name: "Suggestion",
    component: () => import("@/views/suggestion/index")
  },
  {
    path: "/carlist",
    name: "Carlist",
    component: () => import("@/views/carlist/index")
  },
  {
    path: "/service",
    name: "Service",
    component: () => import("@/views/service/index"),
  },
  {
    path: "/serviceDetail",
    name: "serviceDetail",
    component: () => import("@/views/serviceDetail/index"),  
  },
  {
    path: "/brandIntroduction",
    name: "brandIntroduction",
    component: () => import("@/views/aboutUs/brandIntroduction/index"),  
  },
  {
    path: "/brandInformation",
    name: "brandInformation",
    component: () => import("@/views/aboutUs/brandInformation/index"),  
  },
  {
    path: "/brandInformationDetail",
    name: "brandInformationDetail",
    component: () => import("@/views/aboutUs/brandInformationDetail/index"),  
  },
  {
    path: "/contcatUs",
    name: "contcatUs",
    component: () => import("@/views/aboutUs/contcatUs/index"),  
  },
  {
    path: "/custom",
    name: "custom",
    component: () => import("@/views/custom/index"),  
  }
];
export default router;
